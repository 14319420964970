module.exports = {
  defaultTitle: 'Phil Steinke',
  author: 'Phil Steinke',
  url: 'https://philsteinke.com',
  legalName: 'Phil Steinke',
  defaultDescription: 'I’m Phil and I’m a Data Scientist and Front-end Developer!',
  socialLinks: {
    github: 'https://github.com/tgrrr',
    linkedin: 'https://www.linkedin.com/in/philsteinke/',
  },
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'phil@philsteinke.com',
  },
  foundingDate: '2008',
};
